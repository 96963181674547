import styles from './InstrumentChart.module.css'
import { useState } from 'react';
import { AiOutlineBarChart } from '@react-icons/all-files/ai/AiOutlineBarChart';
import { AiOutlineLineChart } from '@react-icons/all-files/ai/AiOutlineLineChart';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';

const CustomHighcharts = dynamic(() => import('./components/CustomHighstock'), {
  ssr: true,
})


import instrumentsApi from '@utils/instrumentsApi';
import Button from '@components/elements/Button';
import dynamic from 'next/dynamic';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import { useRegistrationLink } from '@lib/store/hooks/useActiveProductType';
import { Box, Center } from '@mantine/core';
import NextImage from '@src/components/elements/Image';

const InstrumentChart = (props) => {
  const registrationLink = useRegistrationLink();
  const { gv } = useGlobalVariable();
  const [chartType, setChartType] = useState("candlestick")
  const [resolution, setResolution] = useState("15m")
  const instrumentPrice = instrumentsApi(props.instrumentId, 10000);
  let sell = instrumentPrice?.currentData?.[`${props.instrumentId}`]?.sell;
  let buy = instrumentPrice?.currentData?.[`${props.instrumentId}`]?.buy;

  const { data: chartData } = useSwrHook('getInstrumentChart', {
    query: {
      instrument: props.instrumentId,
      timeframe: resolution,
    },
  });

  const options = {
    plotOptions: {
      candlestick: {
        color: '#ff4848',
        upColor: '#58c914',
        lineColor: '#ff4848'
      },
    },
    rangeSelector: {
      enabled: false,
      buttons: [{
        type: 'all',
        count: 1,
        text: 'All',
      }],
      selected: 0,
      inputEnabled: false,
    },
    series: [{
      name: "display",
      type: chartType,
      data: chartData,
      tooltip: {
        valueDecimals: 2,
      },
      downLineColor: '#ff4848',
      upLineColor: '#58c914',
      dataGrouping: {
        units: [
          [
            'minute',
            [1]
          ]
        ]
      }
    }],
    yAxis: {
      offset: 30,
    },
  };


  return (
    <section>
      <div className="container">
        <div className="pb-xl lg:grid lg:grid-cols-3 lg:gap-10">
          <div className="lg:col-span-2">
            {/* <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className={`flex items-center ${styles.resolution}`}>
                  {["5m", "15m", "30m", "1h", "4h", "1d", "1w"].map((resolution_item, index) => (
                    <div
                      key={index}
                      className={resolution_item == resolution ? `text-secondary` : `text-grey`}
                      onClick={() => {
                        setResolution(resolution_item);
                      }}
                    >
                      {resolution_item}
                    </div>
                  ))}
                </div>
                <div className="flex items-center text-2xl">
                  {[
                    {
                      chart: "candlestick",
                      icon: <AiOutlineBarChart />,
                    },
                    { chart: "line", icon: <AiOutlineLineChart /> },
                  ].map((chart_type_item, index) => (
                    <div
                      key={index}
                      className={`cursor-pointer ltr:ml-md rtl:mr-md ${
                        chartType == chart_type_item.chart ? `text-secondary` : `text-grey`
                      }`}
                      onClick={() => {
                        setChartType(chart_type_item.chart);
                      }}
                    >
                      {chart_type_item.icon}
                    </div>
                  ))}
                </div>
              </div>
              {props.instrumentName && (
                <p className="ml-md hidden text-sm uppercase md:block">{props.instrumentName}</p>
              )}
            </div> */}
            <div className="max-w-full">
              <Box sx={{ height: '400px', position: 'relative' }}>
                <div id="blur_chart" role="status" className="w-full p-4 border border-gray-200 rounded-sm  animate-pulse md:p-6 dark:border-gray-400 " style={{ height: '368px', overflow: 'hidden' }}>
                   <NextImage src="https://cdn.capex.com/uploads/chart_blurred_db4afbafd4/chart_blurred_db4afbafd4.png" layout="fill" objectFit="cover" />
                </div>
                <Center style={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  right: 0, 
                  bottom: 0, 
                  zIndex: 10 
                }}>
                  <Button type="primary" size="lg" link={registrationLink} label={gv('view_chart')} className=" shadow-lg ">
                  {gv('view_chart')}
                  </Button>
                </Center>
              </Box>
            </div>
          </div>
          <div
            className={`flex flex-col  pt-lg md:items-center lg:col-span-1 lg:block lg:pt-xl ${
              styles[`trade-buttons`]
            }`}
          >
            <div className="pb-md">
              <Button
                type="primary"
                link={registrationLink}
                labelPos="below"
                size="lg"
                arrow="right"
                label={gv("sell_button_with_price", {
                  template: {
                    price: sell,
                  },
                })}
                subLabel={gv("start_trading_now")}
              />
            </div>
            <div className="pb-xl lg:pb-2xl">
              <Button
                type="green"
                link={registrationLink}
                labelPos="below"
                size="lg"
                arrow="right"
                label={gv("buy_button_with_price", {
                  template: {
                    price: buy,
                  },
                })}
                subLabel={gv("start_trading_now")}
              />
            </div>
            <p className="text-center text-sm ltr:lg:text-left rtl:lg:text-right">{gv("past_performance")}</p>
          </div>
        </div>
        {props.instrumentName && (
          <div className="pb-xl text-center">
            <p className="mb-lg">
              {gv("instrument_page_trade_online_at", {
                template: {
                  instrument_name: props?.instrumentName,
                },
              })}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default InstrumentChart;
